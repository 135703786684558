#top{
	.header-content{
		-ms-overflow-style: none;
	}
	.header-logo{
		margin-right: auto !important;
	}

	.home--boxes{
		.av_textblock_section{
			min-height: 170px;
			.avia_textblock{
				p{
					width: 275px;
				}
			}
		}
	}
}

.only-ie {
	display: flex!important;
	position: absolute;
    width: 100% !important;
    height: 100%;
    top: 0;
	left: 0;
	align-items: center;
}

.only-ie h3 {
	position: absolute;
    width: 100%;
    text-align: center;
	transform: translateY(-50%);
}

.only-ie h3 a {
	font-weight: 700 !important;
}

.only-ie h3 a:hover{
	color: #F75642 !important;
}

html.menu-collapsed #header .hamburger-content .hamburger-content-inner .bottom-menu-content ul li a{
	height: 50px;
}

html .header .header-inner .header-logo a .logo svg{
	height: 59px;
}

.blend-mode-multiply{
	&, .av-parallax{
		background-image: none !important;
	}
}